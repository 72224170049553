import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { authActions } from '../store/auth';

const RedirectVerifyEmail = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userId = queryParams.get('id');
    const pathName = location.pathname.substring(1, location.pathname.length);

    useEffect(() => {
        let idPath = {userId, pathName};
        dispatch(authActions.storeIdPath(idPath));
        navigate("/");
    },[dispatch, navigate, pathName, userId])

    return ( <></> );
}
 
export default RedirectVerifyEmail ;