import React, { useEffect, useMemo, useState } from "react";
import * as PR from "../../prime-modules/index";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { isIOS, osVersion, isMobile } from 'react-device-detect';
import { cartFilterActions } from "../../store/cart";
import { affiliateTrackingInfoActions } from "../../store/affiliateTracking";
import { Checkmark } from "../../assets/images";
import EsimActivationDialog from "../../shared/components/EsimActivationDialog";
import { stripeRediectAPI } from "../../services/api";
import HeaderMenu from "../layout/HeaderMenu";
import { useToast } from "../../context/ToastContext";

const PaymentSummary = ({redeemVoucher}) => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { labels } = useToast();
    const location = useLocation();
    
    const responseData = useSelector(state => state.payment.paymentResponse);
    const profileStatus = responseData?.paymentResponse?.profileStatus;
    const smdpAddress = responseData?.paymentResponse?.smdpAddress;
    const matchingId = responseData?.paymentResponse?.matchingId;

    const userData = useSelector(state => state.auth.sessionData);
    const randomId = useSelector(state => state.cart.randomId);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
    const getCountry = useSelector(state => state.country.country);

    const [activeeSIM, setActiveeSIM] = useState(false);
    const [payRes, setPayRes] = useState(null);
    const [qrData, setQRData] = useState('');
    const [loading, setLoading] = useState(false);

    const headers = useMemo(() => ({
        sessionid: userData.sessionId || "",
        afid: affiliateTrackingData,
    }), [userData.sessionId, affiliateTrackingData]);

    const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search]);
    const orderid = searchParams.get("orderid");
    const payment_intent = searchParams.get("payment_intent");

    useEffect(() => {
        // Clear local storage when payment process starts
        if ((orderid && randomId) || (profileStatus && smdpAddress && matchingId)) {
            localStorage.removeItem("VIEWED_PAYMENT_SUMMARY");
        }
        const viewedPaymentSummary = localStorage.getItem("VIEWED_PAYMENT_SUMMARY");

        if (viewedPaymentSummary) {
            navigate("/");
            return;
        }

        if (orderid && randomId) {
            setLoading(true);
            const navigateToCountryPage = (errorCode) => {
                navigate(`/${getCountry?.name}-esim?error=payment-failed&code=${errorCode}`)
            };
            const getResponse = (response) => {
                if (response.result === "SUCCESS") {
                    setLoading(false);
                    if (response?.data?.status === "succeeded") {
                        setPayRes(response.data);
                        navigate("/paymentresponse");
                        const { smdpAddress, matchingId } = response?.data?.paymentResponse;
                        dispatch(cartFilterActions.removeRandomIdInPayment());
                        dispatch(cartFilterActions.deleteCatalogCart());
                        dispatch(cartFilterActions.setCatalogCartAmount(0));
                        dispatch(affiliateTrackingInfoActions.deleteAffiliateTrackingInfo());

                        if (matchingId && smdpAddress && response?.data?.qrCode && response?.data?.qrCode !== '') {
                            const qrCode = 'data:image/png;base64,' + response?.data?.qrCode;
                            setQRData(qrCode);
                        }
                    } else {
                        setLoading(false);
                        const errorCode = response?.error?.error ? response.error.error : "PMTMSG-07";
                        navigateToCountryPage(errorCode);
                    }
                } else {
                    setLoading(false);
                    const errorCode = response?.error?.error ? response.error.error : "PMTMSG-07";
                    navigateToCountryPage(errorCode);
                }
            };
            const url = `orderid=${orderid}&payment_intent=${payment_intent}&random_id=${randomId}`;
            stripeRediectAPI(url, headers, getResponse);
        } 
        if (profileStatus && smdpAddress && matchingId) {
            setLoading(false);
            setPayRes(responseData);
            if (responseData?.qrCode) {
                setQRData(`data:image/png;base64,${responseData?.qrCode}`);
            }
            dispatch(cartFilterActions.deleteCatalogCart());
            dispatch(cartFilterActions.setCatalogCartAmount(0));
            dispatch(affiliateTrackingInfoActions.deleteAffiliateTrackingInfo());
        }
    }, [dispatch, getCountry?.name, headers, matchingId, navigate, orderid, payment_intent, profileStatus, randomId, responseData, smdpAddress]);

    useEffect(() => {
        if (payRes) {
            localStorage.setItem("VIEWED_PAYMENT_SUMMARY", true);
            setTimeout(() => {
                localStorage.removeItem("PAYMENT-RESPONSE");   
            }, 1000);
        }
    }, [payRes]);
    

    const downloadQRCode = (qrcode) => {
        const downloadLink = document.createElement("a");
        downloadLink.href = qrcode;
        downloadLink.download = "QR_Code.png";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    return (
        <>
             {!redeemVoucher &&
                <section className="inner-header-section aboutus-header-section payment_summary_header">
                    <div className="header">
                        <header>
                            <HeaderMenu />
                        </header>
                        <div className="fixed-height"></div>
                    </div>
                </section>
            }
            {loading ? (
                <div className="loading-sec">
                    <i className="pi pi-spin pi-spinner"></i>
                    <p>{labels.LBL0186}</p>
                    <div className="space"></div>
                </div>
            ) : (
                payRes && (
                    <section className="paymentsummary-qr-section">
                        <div className="payment-summary">
                                {!redeemVoucher &&
                                    <>
                                        <PR.Image src={Checkmark} alt={labels.LBL0052} />
                                        <h2>{labels.LBL0052}</h2>
                                        <p>{labels.LBL0053}.</p>
                                    </>
                                }
                            {qrData && (qrData !== '') &&
                                <>
                                    {(payRes?.paymentResponse?.profileStatus && (payRes?.paymentResponse?.profileStatus === 'Released')) ?
                                        <>
                                            <span className="scan-text">{labels.LBL0054}</span>

                                            <img src={qrData} id="qrCodeEl" alt="qrcode" className="qr-code" />

                                            {payRes?.email && payRes?.email !== "" &&
                                                <span className="scan-text">{labels.LBL0055} <span className="payer-email"> {payRes?.email}</span> </span>
                                            }
                                            { payRes?.paymentResponse?.smdpAddress && payRes?.paymentResponse?.matchingId && 
                                            <EsimActivationDialog smdpAddress={payRes?.paymentResponse?.smdpAddress} matchingId={payRes?.paymentResponse?.matchingId} activeeSIM={activeeSIM} setActiveeSIM={setActiveeSIM}/>}
                                            <br />
                                            <PR.Button type="button" label={labels.LBL0056} value={labels.LBL0056} className="download-button" onClick={() => downloadQRCode(qrData)} />
                                            {(isMobile && isIOS && (osVersion >= "17.4")) && <PR.Button  onClick={() => setActiveeSIM(true)} type="button" label={labels.LBL0057} className="download-button" />}
                                        </> :
                                        <span className="scan-text">{labels.LBL0058}</span>
                                    }
                                </>
                            }
                        </div>
                    </section>
                )
            )}
        </>
    );
};

export default PaymentSummary;