import { createSlice } from '@reduxjs/toolkit';

const getAffiliateTracking = localStorage.getItem('AFFILIATE-TRACKING');

const affiliateTrackingInfo = (getAffiliateTracking === 'undefined' || getAffiliateTracking === "") ? JSON.stringify(getAffiliateTracking) : (getAffiliateTracking !== null) ? JSON.parse(getAffiliateTracking): '';

const initialAffiliateTrackingInfo = {
    affiliateTrackingInfo: affiliateTrackingInfo,
}

export const affiliateTrackingInfoSlice = createSlice({
    name: 'affiliateTrackingInfo',
    initialState: initialAffiliateTrackingInfo,
    reducers: {
        setAffiliateTrackingInfo(state, action) {
            state.affiliateTrackingInfo = action.payload
            localStorage.setItem("AFFILIATE-TRACKING",JSON.stringify(action.payload));
        },
        deleteAffiliateTrackingInfo(state) {
            state.affiliateTrackingInfo = '';
            localStorage.removeItem('AFFILIATE-TRACKING');
        }
    },
})

export const affiliateTrackingInfoActions = affiliateTrackingInfoSlice.actions;
export default affiliateTrackingInfoSlice.reducer;
