import { createSlice } from '@reduxjs/toolkit';
const getstateKey = localStorage.getItem('stateKey');
const  stateKey= (getstateKey === 'undefined' || getstateKey === "") ? JSON.stringify(getstateKey) : (getstateKey !== null) ? JSON.parse(getstateKey): '';
const initialAuthState = {
    stateKey: stateKey
};
const stateSlice = createSlice({
    name: 'stateKey',
    initialState: initialAuthState,
    reducers: {
        storeStateKey(state, action) {
            state.stateKey = action.payload;
            localStorage.setItem('stateKey', JSON.stringify(action.payload));
        },
        removeStateKey(state) {
            state.stateKey = '';
            localStorage.removeItem('stateKey');
        }
    },
});

export const stateActions = stateSlice.actions;

export default stateSlice.reducer;