export const AutocompleteAddressSplit = (addressList) => {
    // House
    let house = '';
    if (addressList.street_number) {
        house += addressList.street_number + ',';
    } else if (addressList.colloquial_area) {
        house += addressList.colloquial_area + ',';
    }
    house = cleanString(house);

    // Street
    let street = '';
    if (addressList.route) {
        street += addressList.route + ',';
    }
    if (addressList.neighborhood) {
        street += addressList.neighborhood + ',';
    }
    if (addressList.sublocality) {
        street += addressList.sublocality + ',';
    }
    if (addressList.locality) {
        street += addressList.locality + ',';
    }
    street = cleanString(street);
    // End Street

    // City
    let city = '';
    if (addressList.postal_town) {
        city += addressList.postal_town + ',';
    } else if (addressList.administrative_area_level_2) {
        city += addressList.administrative_area_level_2 + ',';
    } else if (addressList.administrative_area_level_1) {
        city += addressList.administrative_area_level_1 + ',';
    } else {
        city += '';
    }
    city = cleanString(city);
    // End City

    const houseVal = house ? house : '';
    const streetVal = street ? street : '';
    const postcodeVal = addressList.postal_code ? addressList.postal_code : '';
    const cityVal = city ? city : '';
    const countryVal = addressList.country ? addressList.country : '';

    const finalList = {
        houseVal : houseVal,
        streetVal : streetVal,
        postcodeVal : postcodeVal,
        cityVal : cityVal,
        countryVal : countryVal
    };

    return finalList;
}

// cleanString('one, ,, , two,two,two, three'); // returns 'one,two,three'
export const cleanString = (str) => {
    // go through each item
    return String(str).split(',').map((item) => {
        // trim value
        return String(item).trim();
    }).filter((item, index, all) => {
        // remove empty items & duplicate values
        return (item !== '') && (index === all.indexOf(item));
    }).join(', ');
}