import Plausible from 'plausible-tracker'
import { envConfig } from '../constants';

const domainName = envConfig.REACT_APP_DOMAIN_NAME;
const plausibleApiHost = envConfig.REACT_APP_PLAUSIBLE_API_HOST;
export const plausible = Plausible({
  domain: domainName,
  apiHost: plausibleApiHost
})

plausible.enableAutoPageviews();