import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "../login/forms.scss";
import * as PR from "../../prime-modules/index";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { formsLogo } from "../../assets/images";
import Login from "../login/Login";
import { useDispatch, useSelector } from "react-redux";
import { authActions } from "../../store/auth";
import Signup from "../login/Signup";
import ForgotPassword from "../ForgotPassword";
import SelectLanguageDropDown from "./LanguageDropDown";
import { useTranslation } from "react-i18next";
import ResetPassword from "../ResetPassword";
import VerifyEmail from "../VerifyEmail";
import { esimIccidActions } from "../../store/esim";
import { affiliateTrackingInfoActions } from "../../store/affiliateTracking";
import { cartFilterActions } from "../../store/cart";
import { scrollToBundlesActions } from "../../store/scrollToBundles";
import { stateActions } from "../../store/stateKey";
import { envConfig } from "../../constants";
import { googleLogin } from "../../services/api";
import PageLoading from "./PageLoading";
import { getAllCountries } from "../../genericFunctions/GetAllCountries";
import { paymentActions } from "../../store/payment";
import { useToast } from '../../context/ToastContext';
import getAssets from "../../assets/data/brandingAssets.json"

const HeaderMenu = () => {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { showToast, labels, general, isBase64Image, header, redeemVoucher } = useToast();
  const queryParams = new URLSearchParams(useLocation().search);
  const afid = queryParams.get("afid");
  const location = useLocation();
  const params = useParams();
  const pathname = window.location.pathname?.toLowerCase();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const { search } = useLocation();
  const getCode = new URLSearchParams(search).get("code");
  const state = new URLSearchParams(search).get("state");
  const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
  const userData = useSelector(state => state.auth.sessionData);
  let username = userData?.firstName + " " + userData?.lastName;
  if (username.length > 12) {
    username = username.slice(0, 10) + '...'
  }
  const cartData = useSelector((state) => state.cart.catalogCart);
  const idPath = useSelector(state => state.auth.idPath)
  const [visible, setVisible] = useState(false);
  const [formState, setFormState] = useState("");
  const accountRef = useRef(null);
  const stateKey = useSelector(state => state.stateKey.stateKey);
  const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
  const [loading, setLoading] = useState(false);

  const headers = useMemo(() => {
    return { afid: affiliateTrackingData };
  }, [affiliateTrackingData]);
  const logoutHandler = () => {
    dispatch(authActions.onLogout());
    dispatch(esimIccidActions.reseteSIMS());
    dispatch(esimIccidActions.deleteEsim());
    dispatch(affiliateTrackingInfoActions.deleteAffiliateTrackingInfo());
    dispatch(cartFilterActions.removeRandomIdInPayment());
    dispatch(paymentActions.removePaymentResponse());
    const getCartObj = { ...cartData };
    delete getCartObj.discountedPercentage;
    delete getCartObj.discountedPrice;
    delete getCartObj.dataAmountForDisplay;
    delete getCartObj.promoCodePrice;
    delete getCartObj.promoCode;
    dispatch(cartFilterActions.setCatalogCart(getCartObj))
  }


  useEffect(() => {
    if (afid) {
      navigate("/");
      dispatch(affiliateTrackingInfoActions.setAffiliateTrackingInfo(afid));
    }
  }, [afid, dispatch, navigate]);

  
  const accountMenu = [
    {
      icon: "pi pi-user",
      label: labels.LBL0080,
      command: () => navigate("/my-account")
    },
    {
      icon: "pi pi-power-off",
      label: labels.LBL0081,
      command: logoutHandler
    }
  ]

  const  isJsonString = (str)  => {
    try {
      JSON.parse(str);
      return true;
    } catch (e) {
      return false;
    }
  }

  const navigatePath = (selectedPath) => {
    selectedPath = `/${i18n.language}${selectedPath}`
    pathname !== selectedPath && navigate(selectedPath)
  }

  const order = isJsonString(header?.HEAD0001) ? JSON.parse(header?.HEAD0001): [];

  

  const menuItems = [
    {
      id: 'home',
      lableId: "MENU001",
      label:header?.MENU001 ? header?.MENU001 : labels.LBL0082,
      className: (pathname === '/' || pathname === '/en'  || pathname === '/nl') ? 'p-menuitem-active' : '',
      command: () => navigatePath("")
    },
    {
      id: 'pricing',
      lableId: "MENU002",
      label:header?.MENU002 ? header?.MENU002 : labels.LBL0066,
      className: params?.country ? 'p-menuitem-active' : '',
      command: () => {
        !params?.country && navigatePath("/country-page")
        dispatch(scrollToBundlesActions.setScrollToBundles(true));
      }
    },
    {
      id: 'affiliate',
      lableId: "MENU004",
      label:header?.MENU004 ? header?.MENU004 : labels.LBL0083,      
      className: (pathname?.includes('/affiliate')) ? 'p-menuitem-active' : '',
      command: () => navigatePath('/affiliate')
    },
    {
      id: 'aboutUs',
      lableId: "MENU005",
      label:header?.MENU005 ? header?.MENU005 : labels.LBL0084,      
      className: (pathname.includes('/about-us')) ? 'p-menuitem-active' : '',
      command: () => navigatePath('/about-us')
    },
    {
      id: 'contact',
      lableId: "MENU006",
      label:header?.MENU006 ? header?.MENU006 : labels.LBL0085,      
      className: (pathname.includes('/contact')) ? 'p-menuitem-active' : '',
      command: () => navigatePath('/contact')
    },
    {
      id: 'redeemVoucher',
      label: redeemVoucher.VOUC001,
      visible: (screenWidth < 960),
      className: (pathname === '/redeem-voucher') ? 'p-menuitem-active menu-item-contact' : 'menu-item-contact',
      command: () => navigatePath('/redeem-voucher')
    },
    {
      id: 'login',
      label: labels.LBL0067,
      visible: (!isLoggedIn && screenWidth < 960),
      command: () => { renderFormState(true, 'login') }
    },
    {
      id: 'signup',
      label: labels.LBL0038,
      visible: (!isLoggedIn && screenWidth < 960),
      command: () => { renderFormState(true, 'signup') }
    },
    {
      id: 'myAccount',
      label: labels.LBL0080 + " (" + username + ")",
      visible: (isLoggedIn && screenWidth < 960),
      command: () => navigate("/my-account"),
      className: 'username'
    },
    {
      id: 'logout',
      label: labels.LBL0081,
      visible: (isLoggedIn && screenWidth < 960),
      command: logoutHandler
    }
  ].sort((a, b) => order.indexOf(a.lableId) - order.indexOf(b.lableId)).filter(m => m.lableId ?( order.length ? order.includes(m.lableId) : true) : true);

  const renderFormState = useCallback((state, formName) => {
    setVisible(state);
    setFormState(formName);
  }, [])

  useEffect(() => {
    if (getCode && state) {
      setLoading(true);
      if (state === stateKey) {
        const loginDetails = (response) => {
          dispatch(stateActions.removeStateKey());
          if (response.result === "SUCCESS") {
            const callingCode = response.data.phone?.callingCode ? response.data.phone?.callingCode : '';
            getAllCountries(showToast, dispatch, callingCode, '');
            dispatch(authActions.onLogin(response.data));
            dispatch(esimIccidActions.deleteEsim());
            renderFormState(false, "")
            navigate('/');
            setLoading(false);
          } else if (response.result === "FAILED") {
            const error = response.error;
            showToast(error.severity, error.errorMsg);
            setTimeout(() => {
              navigate('/')
            }, [2000])
            setLoading(false);
          } else {
            setLoading(false);
          }
        }
        googleLogin(getCode, headers, dispatch, loginDetails)
      } else if (stateKey) {
        dispatch(stateActions.removeStateKey())
        showToast('error', 'USRLGN-06');
        setLoading(false);
      } else {
        setLoading(false);
      }
    } else {
      const countryCode = envConfig.REACT_APP_DEFAULT_PHONE_COUNTRY.toUpperCase();
      getAllCountries(showToast, dispatch, '', countryCode);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getCode, dispatch, state, headers, navigate, renderFormState, stateKey])

  useEffect(() => {
    if(location.hash.includes("#login")) {
      navigate(location.pathname + location?.search)
      !isLoggedIn && renderFormState(true, "login");
    }
  }, [location?.hash, location.pathname, location?.search, navigate, renderFormState, isLoggedIn])

  const start = (
    <Link to="/"><PR.Image className="logo" src={isBase64Image(general.GEN0002) ? `data:image/png;base64,${general.GEN0002}` : getAssets.logo.logo_dark} alt="Logo" /></Link>
  );
  const end = () => {
    return (
      <div className="flex">
        <SelectLanguageDropDown />
        {(screenWidth > 960) && (
          <PR.Button label={redeemVoucher.VOUC001} className="p-button login-btn" onClick={() => { navigatePath('/redeem-voucher') }} />
        )}
        {(screenWidth > 960) && (
          isLoggedIn
            ? <div className="flex align-items-center">
              <div>
                <PR.Menu model={accountMenu} ref={accountRef} popup id="account_menu" className="account-menu-dropdown" />
                <PR.Button icon="pi pi-user" onClick={(event) => accountRef.current.toggle(event)} aria-controls="account_menu" aria-haspopup className="user-button p-button-rounded" />
              </div>
              {userData.firstName &&
                <b>{userData.firstName + " " + userData.lastName}</b>
              }
            </div>
            : <>
              <PR.Button label={labels.LBL0067} onClick={() => { renderFormState(true, 'login') }} className="p-button login-btn" />
              {/* <PR.Button label={labels.LBL0038} onClick={() => { renderFormState(true, 'signup') }} /> */}
            </>)
        }
      </div>
    )
  }

  useEffect(() => {
    if (idPath.pathName === 'reset-password') {
      renderFormState(true, "resetPwd")
    } else if (idPath.pathName === 'verify-email' || idPath.pathName === 'update-email') {
      renderFormState(true, "verifyEmail")
    }
  }, [idPath.pathName, isLoggedIn, renderFormState])

  // Sticky Header ====

  useEffect(() => {
    window.addEventListener('scroll', isSticky);
    return () => {
      window.removeEventListener('scroll', isSticky);
    };
  });
  const isSticky = () => {
    const headerData = document.querySelector('.header');
    if (!headerData) {
      console.error("Header element not found.");
      return;
  }
    const scrollTop = window.scrollY;
    scrollTop >= 1 ? headerData.classList.add('is-sticky') : headerData.classList.remove('is-sticky');
  };


  useEffect(() => {
    const handleWindowResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  });

  return (
    <>
      <div className="header-navigation">
        <PR.Menubar model={menuItems} start={start} end={end} />
      </div>
      <PR.Dialog visible={visible} draggable={false} blockScroll={true} style={{ width: '75%' }} onHide={() => { renderFormState(false, ""); dispatch(authActions.deleteStoreIdPath()) }} className="forms-dialog" resizable={false}>
        <div className="grid grid-nogutter header-text">
          <div className="col-12 lg:col-4 md:col-12 hide-mobile">
            <div className="login-bg">
              <PR.Image className="logo" src={formsLogo} alt="Logo" />
            </div>
          </div>
          <div className="col-12 lg:col-8 md:col-12">
            {formState === 'login'
              ? <Login renderFormState={renderFormState} />
              : formState === 'signup'
                ? <Signup renderFormState={renderFormState} />
                : formState === 'forgotpwd'
                  ? <ForgotPassword renderFormState={renderFormState} />
                  : formState === 'resetPwd'
                    ? <ResetPassword renderFormState={renderFormState} />
                    : formState === 'verifyEmail'
                      ? <VerifyEmail renderFormState={renderFormState} />
                      : <></>
            }
          </div>
        </div>
      </PR.Dialog>
      {
        loading && <PageLoading />
      }
    </>
  );
}
export default HeaderMenu