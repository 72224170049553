import { createSlice } from '@reduxjs/toolkit';
const getsitemaintenanceInfo = localStorage.getItem('SITE-MAINTENANCE');
const sitemaintenanceInfo = (getsitemaintenanceInfo === 'undefined' || getsitemaintenanceInfo === "") ? JSON.stringify(getsitemaintenanceInfo) : (getsitemaintenanceInfo !== null) ? JSON.parse(getsitemaintenanceInfo): '';

const initialSitemaintenanceInfo = {
    sitemaintenanceInfo: sitemaintenanceInfo,
}

export const sitemaintenanceInfoSlice = createSlice({
    name: 'sitemaintenanceInfo',
    initialState: initialSitemaintenanceInfo,
    reducers: {
        setSitemaintenanceInfo(state, action) {
            state.sitemaintenanceInfo = action.payload
            localStorage.setItem("SITE-MAINTENANCE", action.payload);
        },
        deleteSitemaintenanceInfo(state) {
            state.sitemaintenanceInfo = '';
            localStorage.removeItem('SITE-MAINTENANCE');
        }
    },
})

export const sitemaintenanceInfoActions = sitemaintenanceInfoSlice.actions;
export default sitemaintenanceInfoSlice.reducer;
