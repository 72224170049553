import React, { useState, useEffect, useCallback, useMemo } from "react";
import { useDispatch, useSelector } from 'react-redux';
import {
    useStripe,
    useElements,
} from '@stripe/react-stripe-js';
import * as PR from "../../prime-modules/index";
import { envConfig } from "../../constants";
import { stripePayment } from "../../services/api";
import { v4 as uuidv4 } from 'uuid';
import { cartFilterActions } from "../../store/cart";
import { useFormik } from "formik";
import * as Yup from "yup";
import { displayDefaultPhone, handleFirstNameChange, handleLastNameChange, renderPrice, trimFormData } from "../../utils/reuse";
import CustomPhoneNumber from "../CustomPhoneNumber";
import { Autocomplete, LoadScript } from "@react-google-maps/api";
import { useRef } from "react";
import { AutocompleteAddressSplit } from "../../utils/AutoCompleteAddressSplit";
import { fetchCountryInfo } from "../../genericFunctions/GetAllCountries";
import { parsePhoneNumber } from "react-phone-number-input";
import { getPhoneObj } from "../../genericFunctions/GetAllCountries";
import { useToast } from "../../context/ToastContext";

const IdealPayment = (props) => {
    const stripe = useStripe()
    const elements = useElements();
    const { showToast, globalConfig, labels, validations} = useToast();
    const apiKey = envConfig.REACT_APP_GOOGLE_MAP_API_KEY;
    const libraries = globalConfig.libraries;
    const inputRef = useRef();
    const toast = useRef();
    const isAuth = useSelector(state => state.auth.isLoggedIn);
    const dispatch = useDispatch();
    const [display3DPopup, setDisplay3DPopup] = useState(false);
    const [auth3dSecureLink, setAuth3dSecureLink] = useState('');
    const orderAmount = useSelector((state) => state.cart.catalogCartAmount);
    const cartData = useSelector((state) => state.cart.catalogCart);
    const iccid = useSelector(state => state.esim.esimIccid);
    const userData = useSelector(state => state.auth.sessionData);
    const affiliateTrackingData = useSelector(state => state.affiliateTracking.affiliateTrackingInfo);
    const getIPCountry = useSelector((state) => state.country.IPCountry).toLowerCase();
    const getDefaultCallingCode = useSelector((state) => state.allCountries.defaultCallingCode);
    const headers = useMemo(() => {
        return { sessionid: userData.sessionId, afid: affiliateTrackingData };
    }, [userData.sessionId, affiliateTrackingData]);
    const totalPayAmount = ('promoCode' in cartData) ? cartData.discountedPrice : cartData.price;
    const [payLabel, setPayLabel] = useState('');
    const [phoneValue, setPhoneValue] = useState('');
    const [payDisabled, setPayDisabled] = useState(false);
    const [phoneError, setPhoneError] = useState("")
    const [country, setCountry] = useState([]);

    useEffect(() => {
        const paymentLabel = labels.LBL0103 + " " + renderPrice(totalPayAmount)
        setPayLabel(paymentLabel);
    }, [ totalPayAmount, stripe, elements, labels.LBL0103])

    useEffect(() => {
        if (!stripe || !elements) {
            return;
        }
    }, [elements, stripe])

    useEffect(() => {
        const fetchCountryData = async () => {
            const params = {
                countries: '',
                info: ''
            }
            fetchCountryInfo(params, dispatch, setCountry, showToast);
        };

        fetchCountryData();
    }, [dispatch, setCountry, showToast]);

    const on3DSecurePopupHide = useCallback(() => {
        setDisplay3DPopup(false)
        setAuth3dSecureLink('');
        props.paymentProps("", false);
    }, [props])

    const paymentResponse = useCallback((payRes) => {
        if (payRes.status === "succeeded") {
            dispatch(cartFilterActions.removeRandomIdInPayment());
            on3DSecurePopupHide()
            props.paymentProps(payRes, false);
        } else if (payRes.status === "requires_action") {
            props.paymentProps("", true);
            window.location.href =  payRes.authURL
        } else {
            props.paymentProps("", false)
            on3DSecurePopupHide();
            showToast('error', payRes.error);
        }
    }, [dispatch, on3DSecurePopupHide, props, showToast])

    const handleSubmit = async (formData) => {
        formData = trimFormData(formData)
        if (!stripe || !elements) {
            return;
        }

        const paymentMethod = await stripe.createPaymentMethod({
            type: 'ideal',
            ideal: {},
            billing_details: { name: formData.firstName, email: formData.email },
        });
        if (paymentMethod && paymentMethod?.paymentMethod && paymentMethod?.paymentMethod?.id) {
            props.paymentProps("", true);
            createIdealPayment(paymentMethod.paymentMethod)
        } else {
            props.paymentProps("", false)
            setPayDisabled(false);
            showToast('error', 'PMTMSG-15');
        }
    };

    const handleIframe = () => {
        const recieveIframeMsg = (event) => {
            if (event.origin === envConfig.REACT_APP_BASE_URL) {
                paymentResponse(event.data)
            }
        }
        window.addEventListener('message', recieveIframeMsg, false);
    }

    const formInitialValues = {
        firstName: isAuth ? userData.firstName : "",
        lastName: isAuth ? userData.lastName : "",
        email: isAuth ? userData.email : "",
        phone: displayDefaultPhone(isAuth, userData, envConfig, getIPCountry, getDefaultCallingCode),
        address: "",
        street: "",
        city: "",
        postCode: "",
        country: ""
    };

    const validationSchema = () => {
        return Yup.object().shape({
            firstName: Yup.string().trim()
                .required(validations.VLD0007)
                .min(3, validations.VLD0008)
                .max(20,  validations.VLD0009),
            lastName: Yup.string().trim()
                .required(validations.VLD0009)
                .min(3, validations.VLD0010)
                .max(20, validations.VLD0010),
            email: Yup.string().trim()
                .email(validations.VLD0006)
                .required(validations.VLD0005),
            address: Yup.string().trim().required(validations.VLD0018),
            street: Yup.string().trim().required(validations.VLD0019),
            city: Yup.string().trim().required(validations.VLD0020),
            postCode: Yup.string().trim().required(validations.VLD0021),
            country: Yup.string().trim().nullable().required(validations.VLD0022),
        });
    }

    const formik = useFormik({
        initialValues: formInitialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit,
        enableReinitialize: true
    });


    const createIdealPayment = useCallback((paymentMethod) => {
        const getPaymentData = (response) => {
            if (response.result === "SUCCESS") {
                paymentResponse(response.data);
            } else {
                props.paymentProps("", false)
                const error = response.error;
                showToast(error.severity, error.errorMsg);
            }
        }

        const paymentMethodID = paymentMethod?.id
        if (paymentMethodID && paymentMethodID !== '') {
            const getCartObj = { ...cartData };
            delete getCartObj.discountedPercentage;
            delete getCartObj.discountedPrice;
            delete getCartObj.dataAmountForDisplay;
            delete getCartObj.promoCodePrice;

            const dataPlans = [getCartObj];
            const formData = formik.values

            const billingDetails = {
                address: formData.address,
                street: formData.street,
                city: formData.city,
                postCode: formData.postCode,
                country: formData.country
            }
            const customerDetails = {
                firstName: formData.firstName,
                lastName: formData.lastName,
                email: formData.email,
                phone: getPhoneObj(phoneValue, isAuth, userData, formData)
            }

            const deviceInfo = { device: globalConfig.deviceInfo };

            const paymentData = {
                contactInfo: customerDetails,
                billingAddress: billingDetails,
                orderSummary: {
                    dataPlans: dataPlans,
                    totalOrderAmount: orderAmount
                },
                paymentGateway: "Stripe",
                paymentMethodId: paymentMethodID,
                iccid: iccid,
                isEsimCompatible: globalConfig.isEsimCompatible,
                deviceInfo: deviceInfo,
                randomId: uuidv4()
            }
            dispatch(cartFilterActions.setRandomIdForPayment(paymentData.randomId))
            stripePayment(paymentData, headers, dispatch, getPaymentData)
        }
    }, [cartData, dispatch, formik.values, globalConfig.deviceInfo, globalConfig.isEsimCompatible, headers, iccid, isAuth, orderAmount, paymentResponse, phoneValue, props, showToast, userData])


    const handlePlaceChanged = () => {
        const place = inputRef.current.getPlace();
        if (place) {
            let components = {};
            place.address_components.map(e => {
                e.types.map(x => {
                    if (x === 'country') {
                        return components[x] = e.short_name;
                    } else {
                        return components[x] = e.long_name;
                    }
                });
                return components;
            });
            const addressList = AutocompleteAddressSplit(components);
            formik.setFieldValue("address", addressList.houseVal);
            formik.setFieldValue("street", addressList.streetVal);
            formik.setFieldValue("city", addressList.cityVal);
            formik.setFieldValue("postCode", addressList.postcodeVal);
            formik.setFieldValue("country", addressList.countryVal);
            formik.setFieldTouched('address', false, false)
            formik.setFieldTouched('street', false, false)
            formik.setFieldTouched('city', false, false)
            formik.setFieldTouched('postCode', false, false)
        }
    }

    const handleCountryBlur = () => {
        formik.setFieldTouched('country', true);
    };

    useEffect(() => {
        let isValidPhone = parsePhoneNumber(formik.values.phone);
        if(!isValidPhone?.nationalNumber) {
            setPhoneError(validations.VLD0023)
        } else if(isValidPhone?.nationalNumber === undefined  || isValidPhone?.nationalNumber.length <=6 ) {
            setPhoneError(validations.VLD0024)
        } else {setPhoneError("")}
    }, [formik.values.phone, validations.VLD0023, validations.VLD0024])

    return (
        <>
            <PR.Toast ref={toast} position="top-right" />
            <div className="payment-form">
                <form onSubmit={formik.handleSubmit} autoComplete="off" className="mt-5">
                    <h3>{labels.LBL0187}</h3>
                    <div className="grid">
                        <div className="col-12 lg:col-6 md:col-6">
                            <PR.InputText id="firstName" name="firstName" value={formik.values.firstName} keyfilter={"alphanum"} onChange={(e) => handleFirstNameChange(e, formik)} onBlur={formik.handleBlur} placeholder={labels.LBL0001} style={{ 'width': '100%' }} autoComplete="off" />
                            {formik.errors.firstName && formik.touched.firstName ? <div className='error-message'>{formik.errors.firstName}</div> : ''}
                        </div>
                        <div className="col-12 lg:col-6 md:col-6">
                            <PR.InputText id="lastName" name="lastName" value={formik.values.lastName} keyfilter={"alphanum"} onChange={(e) => handleLastNameChange(e, formik)} onBlur={formik.handleBlur} placeholder={labels.LBL0002} style={{ 'width': '100%' }} autoComplete="off" />
                            {formik.errors.lastName && formik.touched.lastName ? <div className='error-message'>{formik.errors.lastName}</div> : ''}
                        </div>
                    </div>

                    <div className="grid">
                        <div className="col-12 lg:col-6 md:col-6">
                            <PR.InputText id="email" name="email" value={formik.values.email} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder={labels.LBL0010} style={{ 'width': '100%' }} autoComplete="off" disabled={isAuth} />
                            {formik.errors.email && formik.touched.email ? <div className='error-message'>{formik.errors.email}</div> : ''}
                        </div>
                        <div className="col-12 lg:col-6 md:col-6">
                            <CustomPhoneNumber formik={formik} setPhoneValue={setPhoneValue} setDisableBtn={setPayDisabled} phoneError={phoneError} />
                        </div>
                    </div>

                    <h3>{labels.LBL0060}</h3>
                    <LoadScript scriptjs={false} googleMapsApiKey={apiKey} libraries={libraries}>
                        <Autocomplete
                            onLoad={ref => inputRef.current = ref}
                            onPlaceChanged={handlePlaceChanged}>
                            <span className="p-input-icon-right" style={{ 'display': 'block' }}>
                                <i className="pi pi-map-marker" />
                                <PR.InputText id="addresstext" name="addresstext" placeholder={labels.LBL0188} style={{ 'width': '100%' }} />
                            </span>
                        </Autocomplete>
                    </LoadScript>
                    <div className="grid">
                        <div className="col-12 lg:col-6 md:col-6">
                            <PR.InputText id="address" name="address" value={formik.values.address}
                                onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder={labels.LBL0189} style={{ 'width': '100%' }} autoComplete="off" />
                            {formik.errors.address && formik.touched.address ? <div className='error-message'>{formik.errors.address}</div> : ''}
                        </div>
                        <div className="col-12 lg:col-6 md:col-6">
                            <PR.InputText id="street" name="street" placeholder={labels.LBL0190} value={formik.values.street} onChange={formik.handleChange} onBlur={formik.handleBlur} style={{ 'width': '100%' }} autoComplete="off" />
                            {formik.errors.street && formik.touched.street ? <div className='error-message'>{formik.errors.street}</div> : ''}
                        </div>
                    </div>

                    <div className="grid">
                        <div className="col-12 lg:col-6 md:col-6">
                            <PR.InputText id="city" name="city" placeholder={labels.LBL0191} value={formik.values.city} onChange={formik.handleChange} onBlur={formik.handleBlur} style={{ 'width': '100%' }} autoComplete="off" />
                            {formik.errors.city && formik.touched.city ? <div className='error-message'>{formik.errors.city}</div> : ''}
                        </div>
                        <div className="col-12 lg:col-6 md:col-6">
                            <PR.InputText id="postCode" name="postCode" value={formik.values.postCode} onChange={formik.handleChange} onBlur={formik.handleBlur} placeholder={labels.LBL0192} style={{ 'width': '100%' }} autoComplete="off" />
                            {formik.errors.postCode && formik.touched.postCode ? <div className='error-message'>{formik.errors.postCode}</div> : ''}
                        </div>
                    </div>

                    <PR.Dropdown
                        id="country"
                        name="country"
                        value={formik.values.country}
                        options={country}
                        onChange={formik.handleChange}
                        onHide={handleCountryBlur}
                        resetFilterOnHide
                        optionLabel="label"
                        optionValue="value"
                        showClear placeholder={labels.LBL0193}
                        style={{ 'width': '100%' }}
                        filter
                        filterBy="label"
                        className="country-dropdown"
                    />
                    {formik.errors.country && formik.touched.country ? <div className='error-message'>{formik.errors.country}</div> : ''}
                    <PR.Button type="submit" label={payLabel} className="ideal_payment_button" disabled={payDisabled || phoneError} style={{ 'width': '100%' }} />
                </form>

                <PR.Dialog header="" visible={display3DPopup} breakpoints={{ '960px': '85vw' }} style={{ width: '35vw' }} onHide={() => on3DSecurePopupHide()} resizable={false}>
                    <iframe title="3d Secure" src={auth3dSecureLink} width="100%" id="secure3dRef" className="payment-iframe" onLoad={handleIframe} />
                </PR.Dialog>
            </div>
        </>
    )
};

export default IdealPayment;